import React from "react";
import ReactDOM from "react-dom";
import { Route, BrowserRouter as Router } from "react-router-dom";
import "./index.css";
import App from "./App";
import MondaySecret from "./components/mondaySecret";
import TuesdaySecret from "./components/tuesdaySecret";
import WednesdaySecret from "./components/wednesday/Wednesday";
import LightsOut from "./components/thursday/Thursday";
import PlantsGift from "./components/PlantsGift_Friday";
import * as serviceWorker from "./serviceWorker";

const routing = (
  <Router>
    <div>
      <Route exact path="/" component={App} />
      {/* <Route path="/sunday" component={SundaySecret} /> */}
      <Route path="/monday" component={MondaySecret} />
      <Route path="/tuesday" component={TuesdaySecret} />
      <Route path="/wednesday" component={WednesdaySecret} />
      <Route path="/thursday" component={LightsOut} />
      <Route path="/friday" component={PlantsGift} />
    </div>
  </Router>
);

ReactDOM.render(routing, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
