import React from "react";
import "../App.css";
export default class WelcomeMessage extends React.Component {
  render() {
    return (
      <div className="flexDiv">
        <span>
          Aloha<b className="redFont">Verms!</b>
        </span>
        <span className="normalfont">
          Happy <b className="redFont">Birthday Week</b> !
        </span>
        <span className="normalfont">
          {"Did you think we would forget to update this website this year? 😏"}
        </span>
      </div>
    );
  }
}
