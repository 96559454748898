import React from "react";
import "../../App.css";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { Formik, Form, Field } from "formik";

export default class ThursdaySecret extends React.Component {
  render() {
    const validateAnswer = value => {
      let error;

      if (value.toLowerCase().trim() !== "mirror") {
        error = "Oops, Wrong Answer Simmi V!";
      } else {
        error = "Find your gift above your mirror :) ";
      }

      return error;
    };
    const onSubmit = values => {
      // console.log(values);
    };
    return (
      <div className="App-header align-left">
        <div className="flex-left">
          <p className="font-yellow">Special Gift = Harder game.</p>
          <p className="font-yellow"> One Hint. Guess if you can ;)</p>
          {/* <p>You see what I show.</p> */}
          <p className="font-mediumseagreen">
            Hint: If you smile, I'll always smile back.
          </p>
        </div>
        <Formik
          initialValues={{
            answer: ""
          }}
          onSubmit={onSubmit}
        >
          {({ errors, touched }) => (
            <Form className="smallfont flex-left">
              <label className="text-muted">Answer:</label>
              <Field
                className="input-box"
                name="answer"
                validate={validateAnswer}
              />
              <span className="smallfont-margin5">
                {errors.answer && touched.answer && errors.answer}
              </span>
              <Button size="sm" variant="success" type="submit">
                Submit your guess
              </Button>
            </Form>
          )}
        </Formik>
        {/* <Form className="smallfont flex-left">
          <Form.Label className="text-muted">
            Guess what the gift is?
          </Form.Label>
          <Form.Control.Feedback type="invalid">
            WRONG ANSWER!
          </Form.Control.Feedback>
          <Form.Control
            className="input-box"
            type="text"
            placeholder="Enter answer"
          />
          <Form.Text className="text-muted margin-bottom-5">
            Correct answer reveals location
          </Form.Text>
          <Button size="sm" variant="success" type="submit">
            Submit form
          </Button>
        </Form> */}

        <Link to="/">
          <Button
            className="margin5"
            to="/monday"
            renderAs={Link}
            variant="info"
            size="sm"
          >
            Go Back!
          </Button>
        </Link>
      </div>
    );
  }
}
