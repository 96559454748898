import React from "react";
import "../App.css";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { Formik, Form, Field } from "formik";

export default class MondaySecret extends React.Component {
  render() {
    const validateAnswer = value => {
      let error;

      if (value.toLowerCase().trim() !== "watch") {
        error = "Oops, Wrong Answer Simmi V!";
      } else {
        error = "Find your gift in your bedside table!";
      }

      return error;
    };
    const onSubmit = values => {
      console.log(values);
    };
    return (
      <div className="App-header align-left">
        <div className="flex-left">
          <p className="font-yellow">
            I can be new, I can be old, I am the same.
          </p>
          <p>I can break, and what breaks me is what I show you.</p>
          <p className="font-mediumseagreen">
            What I show you has kept moving, and will keep moving forever.
          </p>
        </div>
        <Formik
          initialValues={{
            answer: ""
          }}
          onSubmit={onSubmit}
        >
          {({ errors, touched }) => (
            <Form className="smallfont flex-left">
              <label className="text-muted">Guess what the gift is?</label>
              <Field
                className="input-box"
                name="answer"
                validate={validateAnswer}
              />
              <span className="smallfont-margin5">
                {errors.answer && touched.answer && errors.answer}
              </span>
              <label className="text-muted">
                Correct answer gets you the gift location
              </label>
              <Button size="sm" variant="success" type="submit">
                Submit your guess
              </Button>
            </Form>
          )}
        </Formik>
        {/* <Form className="smallfont flex-left">
          <Form.Label className="text-muted">
            Guess what the gift is?
          </Form.Label>
          <Form.Control.Feedback type="invalid">
            WRONG ANSWER!
          </Form.Control.Feedback>
          <Form.Control
            className="input-box"
            type="text"
            placeholder="Enter answer"
          />
          <Form.Text className="text-muted margin-bottom-5">
            Correct answer reveals location
          </Form.Text>
          <Button size="sm" variant="success" type="submit">
            Submit form
          </Button>
        </Form> */}

        <Link to="/">
          <Button
            className="margin5"
            to="/monday"
            renderAs={Link}
            variant="info"
            size="sm"
          >
            Go Back!
          </Button>
        </Link>
      </div>
    );
  }
}
