import logos from "./logos";
import randomSubarray from "./random-subarray";
import { GAME_STARTED } from "./game-states";

export default {
  newGame: () => {
    let randomLogos = randomSubarray(logos, 20);
    randomLogos = randomLogos.concat(randomLogos);

    return {
      gameState: GAME_STARTED,
      tiles: randomSubarray(randomLogos, 40).map((tile, tileId) => ({
        id: tileId,
        name: tile.name,
        flipped: false,
        logo: `${tile.name.toLowerCase()}.png`
      })),
      temporaryFlippedTiles: {},
      moves: 0
    };
  }
};
