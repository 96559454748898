export default [
  {
    name: "abhishek"
  },
  {
    name: "aditya"
  },
  {
    name: "akshey"
  },
  {
    name: "ankita"
  },
  {
    name: "arshiya"
  },
  {
    name: "ashish"
  },
  {
    name: "ashwin"
  },
  {
    name: "chandni"
  },
  {
    name: "dexter"
  },
  {
    name: "kumkum"
  },
  {
    name: "megha"
  },
  {
    name: "reena"
  },
  {
    name: "rox"
  },
  {
    name: "sam"
  },
  {
    name: "sanchi"
  },
  {
    name: "sanjiv"
  },
  {
    name: "shaun"
  },
  {
    name: "shivani"
  },
  {
    name: "simmi"
  },
  {
    name: "vikalp"
  },
  {
    name: "yashwant"
  },
  {
    name: "snow"
  }
];
