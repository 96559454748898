import React from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { Button, Badge } from "react-bootstrap";
import WelcomeMessage from "./components/welcomeMessage";
import Confetti from "./components/confetti";
import { Link } from "react-router-dom";

class App extends React.Component {
  render() {
    return (
      <div className="App-header">
        <Confetti />
        <WelcomeMessage />
        <div className="flexDivHorizontal">
          <Link to="/friday">
            <Button
              className="margin5"
              to="/friday"
              renderAs={Link}
              variant="success"
              size="sm"
            >
              Let's go!
            </Button>
          </Link>
          <h6>
            <Badge variant="warning">New</Badge>
          </h6>
        </div>
      </div>
    );
  }
}

export default App;
