import React from "react";
import Typical from "react-typical";
import "../App.css";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

export default class PlantsGift_Friday extends React.Component {
  render() {
    return (
      <div className="App-header">
        <div className="smallfont flexDiv">
          <p>
            Plans change (thanks lockdown) so Ash's team had to
            <b className="redFont"> improvise:</b>
            <Typical
              steps={[
                3000,
                "We had very little time!!!",
                2000,
                "but we managed to pull it together!",
                2000,
                "Your first gift:",
                2000,
                "will brighten your working space",
                5000,
                "GO FIND IT! 😉",
              ]}
              loop={1}
              wrapper="p"
            />
          </p>
        </div>
        <Link to="/">
          <Button
            className="margin5"
            to="/monday"
            renderAs={Link}
            variant="info"
            size="sm"
          >
            Go back to Home
          </Button>
        </Link>
      </div>
    );
  }
}
